import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Mixpanel } from '../../../utils/mixpanel';
import './ClassroomsCreate.scss';
import { createClassroom, addStudentsToClassroom, addTeacherToClassroom } from '../../../services/classroom';
import Button from '../../../components/elements/DashboardButton';
import { refreshClassrooms, refreshStudents } from '../../../utils/functions';

class ClassroomsCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classroomName: '',
      teacherEmail: '',
      selectedGradeLevels: [],
      gradeLevels: [],
      currLocale: props.currLocale,
      addStudentsText: '',
      sendEmail: false,
      isButtonDisabled: false,
      teacherInfo: JSON.parse(sessionStorage.getItem('teacherData'))
    }

    this.saveClassroomName = this.saveClassroomName.bind(this);
    this.saveTeacherEmail = this.saveTeacherEmail.bind(this);
    this.selectGradeLevel = this.selectGradeLevel.bind(this);
    this.createNewClassroom = this.createNewClassroom.bind(this);
    this.chooseFile = this.chooseFile.bind(this);
  }

  componentDidMount() {
    this.csvSelector = document.querySelector('#csvSelector');
    this.csvSelector.addEventListener('change', (e) => {
      const file = this.csvSelector.files[0];
      this.importFile(file);
    });

    const levels = ClassroomsCreate.buildGradeLevels(this.props.currLocale);
    this.setState({ gradeLevels: levels });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currLocale !== prevState.currLocale) {
      return {
        gradeLevels: ClassroomsCreate.buildGradeLevels(nextProps.currLocale)
      };
    }
    return null;
  }

  static buildGradeLevels(currLocale) {
    let levels = [];

    if (currLocale === 'fr_FR') {
      levels = ['GS','CP','CE1','CE2','CM1','CM2'];
    } else {
      levels = ['K','1','2','3','4','5','6+'];
    }

    return levels;
  }

  chooseFile(e) {
    e.preventDefault();
    this.csvSelector.click();
  }

  importFile(file) {
    var reader = new FileReader();

    reader.onload = (e) => {
      const text = reader.result;
      this.setState({ addStudentsText: text });
    }

    reader.readAsText(file);
  }

  validEmail(email) {
    const re = /.+@.+/;
    return re.test(email);
  }

  saveTeacherEmail(e) {
    this.setState({ teacherEmail: e.target.value });
  }

  saveClassroomName(e) {
    const name = e.target.value;
    this.setState({ classroomName: name.trim() });
  }

  selectGradeLevel(e) {
    const selectedGradeLevels = this.state.selectedGradeLevels;

    //if user already selected level, remove it, else add it
    const levelIndex = selectedGradeLevels.indexOf(e.target.innerText);
    if (levelIndex > -1) {
      selectedGradeLevels.splice(levelIndex, 1);
    } else {
      selectedGradeLevels.push(e.target.innerText);
    }

    this.setState({ selectedGradeLevels: selectedGradeLevels });
  }

  createNewClassroom(e) {
    e.preventDefault();

    let students = this.state.addStudentsText !== '' ? this.state.addStudentsText.split('\n') : [];
    let emailCol = null;
    let classroomId;
    let hasFormError = false;
    const { classroomName } = this.state;

    if (!classroomName) {
      this.setState({ error: 'Missing a required field'});
      window.scrollTo(0, 0);
      return;
    }

    this.setState({
      error: null,
      isButtonDisabled: true,
    });

    students = students.reduce((memo, student, index) => {
      student = student.split(/[\t,]+/);

      for (var i = 0; i < student.length; i++) {
        if (this.validEmail(student[i])) {
          emailCol = i;
        }
      }

      let studentName = '';
      if (emailCol === null || emailCol > 1) {
        studentName = student[1] ? student[0].trim() + ' ' + student[1].trim() : student[0].trim();
      } else if (emailCol === 1) {
        studentName = student[0].trim();
      }

      const parentEmail = student[emailCol] ? student[emailCol].trim() : '';
      if (studentName.length > 64 || parentEmail.length > 64) {
        this.setState({
          error: 'dashboard.students.create.error',
          isButtonDisabled: false,
        });
        hasFormError = true;
      }
      memo.push({
        fullName: studentName,
        parentEmail: parentEmail
      });
      return memo;
    }, []);

    if (hasFormError) {
      window.scrollTo(0, 0);
      return;
    }

    createClassroom({
      teacherId: this.state.teacherInfo.objectId,
      classroomName: this.state.classroomName,
      grades: this.state.selectedGradeLevels
    }).then((classroom) => {
      refreshClassrooms(this.state.teacherInfo.objectId);
      classroomId = classroom.id;

      if (students.length !== 0){
        return addStudentsToClassroom({
          classroomId: classroomId,
          studentDetails: students,
          sendEmail: this.state.sendEmail,
        });
      }
    }).then((success) => {
      refreshStudents(this.state.teacherInfo.objectId);

      if (this.state.teacherEmail) {
        addTeacherToClassroom({
          classroomId: classroomId,
          teacherEmail: this.state.teacherEmail,
        });
      }
      if (students.length > 0) {
        Mixpanel.track('Dashboard (v1.3): Student Added');
      }

      this.props.history.push({
        pathname: '/dashboard/classrooms/create/success',
        state: {
          classroomId: classroomId,
          classroomName: this.state.classroomName,
          studentCount: students.length
        }
      });
    }).catch((error) => {
       console.error(error);
    });
  }

  render() {
    const { vocabulary, siteContent } = this.props;

    return (
      <div className="classrooms-create-container page">
        <Helmet>
          <title>Create a Classroom | codeSpark Academy Teacher Dashboard</title>
        </Helmet>
        <form className="classroom-form">
          <h2>create a {vocabulary["dashboard.classroom.text"]}</h2>
          <div className={`form-error ${this.state.error ? 'is-visible' : ''}`}>{vocabulary[this.state.error]}</div>
          <ol className="form-sections">
            <li>
              <div className="section-header">
                <h3 className="form-label">name {vocabulary["dashboard.classroom.text"]}</h3>
                <input type="text" className="form-input" placeholder={`${vocabulary["dashboard.classroom.text"]} name`} onChange={ this.saveClassroomName } />
              </div>
            </li>
            {
              this.props.siteContent.dashboard.modules.classrooms.fields.includes('grades') &&
              <li>
                <div className="section-header">
                  <h3 className="form-label">pick grades</h3>
                  <ul className="grade-levels">
                  {
                    this.state.gradeLevels.map(level => (
                      <li key={level}>
                        <button type="button" onClick={ e => this.selectGradeLevel(e) }
                          className={ this.state.selectedGradeLevels.indexOf(level) > -1 ? 'selected' : '' }
                        >
                          { level }
                        </button>
                      </li>
                    ))
                  }
                  </ul>
                </div>
              </li>
            }
            <li>
              <div className="section-header">
                <h3 className="form-label">add additional {vocabulary["dashboard.teacher.text"]}</h3>
                <input type="email" className="form-input" placeholder="email address" onChange={ this.saveTeacherEmail }/>
              </div>
            </li>
            <li>
              <div className="section-header">
                <h3 className="form-label">add {vocabulary["dashboard.student.textPlural"]}</h3>
                <p>
                  Type or copy and paste from a spreadsheet.
                  <Button
                    className="button--xs button--white button--shadow"
                    onClick={ this.chooseFile }
                    eventParams={{ name: 'Import Student CSV' }}
                  >Import CSV</Button>
                </p>
                <input type="file" className="hide" id="csvSelector" accept=".csv" />
              </div>
              <div className="section-content">
                <div className="form-group">
                  <label />
                  <div>
                    <p>
                        {vocabulary['dashboard.student.textUpper']}'s First Name and Last Initial
                      {
                        !siteContent.isPartner &&
                        <span>, Parent@email.com (optional)</span>
                      }
                    </p>
                    <textarea
                      name="addStudents"
                      placeholder={ `Ada L${siteContent.isPartner ? '' : ', mom@mom.com'}\nAlan T${siteContent.isPartner ? '' : ', dad@dad.com'}` }
                      value={ this.state.addStudentsText }
                      onChange={ e => this.setState({ addStudentsText: e.target.value }) }
                    />
                    {
                      !siteContent.isPartner &&
                      <div>
                        <input
                            type="checkbox"
                            checked={this.state.sendEmail}
                            onChange={ e => this.setState({ sendEmail: e.target.checked }) }
                        />
                        <label>Send a welcome email to each parent's email address</label>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </li>
          </ol>
          <div className="center">
            <Button
              className="button--small button--wide"
              onClick={this.createNewClassroom}
              disabled={this.state.isButtonDisabled}
              eventParams={{ name: 'Create Classroom' }}
            >create</Button>
          </div>
          <div className="privacy">
            <strong>Privacy</strong> <a href="https://codespark.com/privacy" target="_blank" rel="noopener noreferrer">Click here</a> for our privacy document that can be shared or printed for your {vocabulary["dashboard.student.textPlural"]}.
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(ClassroomsCreate);
