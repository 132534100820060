import React from 'react';
import __reduce from 'lodash/reduce';
import __get from 'lodash/get';
import __camelCase from 'lodash/camelCase';
import __toLower from 'lodash/toLower';

import './ClassroomsDetailStudents.scss';
import StudentPasswordRow from './StudentPasswordRow';
import { resetStudentPassword, calculateStudentsProgress } from '../../../../services/students';
import { getCurrentUserID } from '../../../../services/teacher';
import { Mixpanel } from '../../../../utils/mixpanel';
const s3Url = process.env.REACT_APP_S3_IMAGES_URL;

class ClassroomsDetailStudents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: 'all',
      topics: [
        { key: 'Sequencing', title: 'topic.sequencing.title', description: 'topic.sequencing.description' },
        { key: 'Loops', title: 'topic.loops.title', description: 'topic.loops.description' },
        { key: 'Advanced Sequencing', title: 'topic.advSequencing.title', description: 'topic.advSequencing.description' },
        { key: 'Events', title: 'topic.events.title', description: 'topic.events.description' },
        { key: 'Conditionals', title: 'topic.conditionals.title', description: 'topic.conditionals.description' },
        { key: 'Booleans', title: 'topic.booleans.title', description: 'topic.booleans.description' },
        // { key: 'Variables', title: 'topic.variables.title', description: 'topic.variables.description' },
        { key: 'Tutorials', title: 'topic.studioTutorials.title', description: 'topic.studioTutorials.description' },
        { key: 'GameKits', title: 'topic.gamekits.title', description: 'topic.gamekits.description' },
        { key: 'StacksQueues', title: 'topic.stacksAndQueues.title', description: 'topic.stacksAndQueues.description'},
        // { key: 'VariablesInequalities', title: 'topic.variablesInequalities.title', description: 'topic.variablesInequalities.description'}
        { key: 'StoryIdeaTutorials', title: 'topic.storyTutorials.title', description: 'topic.storyTutorials.description'},
        { key: 'Inequalities', title: 'topic.inequalities.title', description: 'topic.inequalities.description'},
      ],
      students: [],
      showStudent: null,
      levels: {},
      passwordEnabled: false,
      reload: false
    };

    this.getStudentInfo = this.getStudentInfo.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const company = __toLower(__camelCase(this.props.siteContent.company));
    const missionsData = require(`../../../../services/data/${company}/missions`);
    const missions = missionsData.default;
    const levels = __reduce(missions, (memo, mission) => {
      const { topic, name } = mission;
      memo[topic] = memo[topic] || [];
      memo[topic].push(name);
      return memo;
    }, {});

    this.setState({ levels: levels });
    this.getStudentInfo();
  }

  componentDidUpdate(prevProps) {
    const { classroomId } = this.props;

    if (prevProps.classroomId !== classroomId) {
      this.getStudentInfo();
    }
  }

  resetPassword(studentId) {
    resetStudentPassword(studentId).then((results) => {
      window.location.reload();
    });
  }

  getStudentInfo() {
    const { classroomId, siteContent, classroomDetails } = this.props;
    const company = __toLower(__camelCase(siteContent.company));

    if (classroomDetails.options?.hasOwnProperty('StudentPasswords') === false) {
      this.setState({ passwordEnabled: false });
    } else {
      this.setState({ passwordEnabled: !classroomDetails.options?.StudentPasswords?.isRestricted });
    }
    if (classroomId) {
      const studentsProgress = calculateStudentsProgress(company, classroomDetails.students);
      this.setState({ students: studentsProgress });
    }
  }

  renderStars(student, level, filter) {
    if (filter === 'Tutorials' && student.missionProgress[filter] && student.missionProgress[filter][level]) {
      return [0, 1, 2].map(index => (
        <span key={index} className="fa fa-star checked" />
      ));
    }

    if (filter === 'StoryIdeaTutorials' && student.missionProgress[filter] && student.missionProgress[filter].includes(level)) {
      return [0, 1, 2].map(index => (
        <span key={index} className="fa fa-star checked" />
      ));
    }

    return [0, 1, 2].map(index => (
      <span key={index} className={`fa fa-star ${__get(student.missionProgress, [filter, level, 'stars']) > index ? 'checked' : ''}`} />
    ));
  }

  onViewProgressChange(event) {
    const { value } = event.target;
    const eventCategoryMapping = {
      'all': 'All',
      'Tutorials': 'Game Maker Tutorials',
      'GameKits': 'Game Ideas',
      'StacksQueues': 'Stacks and Queues',
      'StoryIdeaTutorials': 'Story Ideas',
    };
    const category = eventCategoryMapping[value] ? eventCategoryMapping[value] : value;
    this.setState({ filter: value });
    Mixpanel.track('Dashboard (v1.4): Classroom: Progress Tracker', {
      parseAccountId: getCurrentUserID(),
      category,
    });
  }

  render() {
    const { vocabulary } = this.props;
    const { students, topics, levels, filter, showStudent, passwordEnabled } = this.state;

    return (
      <div className="classrooms-detail-students-container">
        <h3 className="modal-title">
          { showStudent && <span className="back" onClick={ () => this.setState({ showStudent: null }) }>{vocabulary['dashboard.classrooms.show.students.return']}</span> }
          { (showStudent && showStudent.name) || vocabulary['dashboard.classrooms.show.studentProgress.view'] }
        </h3>
        <div className="modal-content modal-content--progress">
          <div className="table-filter">
            view progress in
            <select value={filter} onChange={ (e) => this.onViewProgressChange(e) }>
              <option value="all">All</option>
              {
                topics.map(topic => (
                    <option key={topic.key} value={topic.key}>{vocabulary[topic.title]}</option>
                ))
              }
            </select>
          </div>
          <div className="students-table">
            <div className={`students-table-header-row ${showStudent ? 'no-column-header' :  ''} ${passwordEnabled ? 'has-password-header' : ''}`}>
              {
                passwordEnabled &&
                <div className="password-header">
                  <span className="vertical">
                    Password
                    <p>(Tap Image to Reset)</p>
                  </span>
                </div>
              }
              {
                filter === 'all' && topics.map(topic => (
                  <div key={topic.key} className="header">
                    <span
                      className={ showStudent ? '' : 'vertical' }
                      onClick={() => this.setState({ filter: topic.key })}
                    >
                      { vocabulary[topic.title] }
                    </span>
                  </div>
                ))
              }
              {
                filter !== 'all' && typeof levels[filter] === 'object' && levels[filter].map(level => (
                  <div key={level} className="header">
                    <span className={ showStudent ? '' : 'vertical' }>{ level }</span>
                  </div>
                ))
              }
            </div>
            {
              !showStudent && students.map( (student, i) => (
                <div key={i} className="student-row">
                  <div className="student-name">
                    <div>
                      {
                        filter === 'all' ? (
                          <span className={`icon sprite ${student.totalProgress}`} />
                        ) : (
                          <span className={`icon sprite ${student.progress[filter] || 'progress_0'}`} />
                        )
                      }
                      <span className="student-link" onClick={ () => this.setState({ showStudent: student }) }>{ student.name }</span>
                    </div>
                    {
                      passwordEnabled &&
                      <StudentPasswordRow student={student} resetPassword={this.resetPassword} />
                    }

                  </div>
                  {
                    filter === 'all' && topics.map(topic => (
                      <div key={topic.key} className="student-progress">
                        {
                          student.progress && student.progress[topic.key] ? (
                            <span className={`icon sprite ${student.progress[topic.key]}`} />
                          ) : (
                            <img className="icon" src={`${s3Url}/no_progress.png`} alt="no progress" />
                          )
                        }
                      </div>
                    ))
                  }
                  {
                    filter !== 'all' && levels[filter].map(level => (
                      <div key={level} className="student-progress">
                        <div>
                          {this.renderStars(student, level, filter)}
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
            {
              showStudent && (
                <div className="student-row detailed">
                  {
                    filter === 'all' && topics.map(topic => (
                      <div key={topic.key} className="student-progress">
                        {
                          showStudent.progress && showStudent.progress[topic.key] ? (
                            <span className={`icon sprite ${showStudent.progress[topic.key]}`} />
                          ) : (
                            <img className="icon" src={`${s3Url}/no_progress.png`} alt="no progress" />
                          )
                        }
                      </div>
                    ))
                  }
                  {
                    filter !== 'all' && levels[filter].map(level => (
                      <div key={level} className="student-progress">
                        <div className="stars">
                          {this.renderStars(showStudent, level, filter)}
                        </div>
                        <div>games played</div>
                        <div className="stat">1</div>
                        <div>time spent</div>
                        <div className="stat">{__get(showStudent.missionProgress, [filter, level, 'timeSpent']) ? showStudent.missionProgress[filter][level].timeSpent : 0} secs</div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ClassroomsDetailStudents;
