import React from 'react';
import __findIndex from 'lodash/findIndex';
import __orderBy from 'lodash/orderBy';

import { Mixpanel } from '../../../../utils/mixpanel';
import './ClassroomsDetailManageStudents.scss';
import { addStudentsToClassroom } from '../../../../services/classroom';
import {
  updateStudent,
  setStudentArchiveStatus,
  transferStudent,
} from '../../../../services/students';
import Button from '../../../../components/elements/DashboardButton';
import DropdownSelect from '../../../../components/global/DropdownSelect';

class ClassroomsDetailManageStudents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showManageStudents: true,
      showAddStudents: true,
      students: [],
      addStudentsText: '',
      sendEmail: false,
      selectedClassroom: {},
      classroomOptions: [],
      transferSuccess: false,
      addSuccess: false,
    };

    this.toggleView = this.toggleView.bind(this);
    this.chooseFile = this.chooseFile.bind(this);
    this.addStudents = this.addStudents.bind(this);
    this.updateParentEmail = this.updateParentEmail.bind(this);
    this.updateStudent = this.updateStudent.bind(this);
    this.selectStudent = this.selectStudent.bind(this);
    this.archiveStudent = this.archiveStudent.bind(this);
    this.transferStudents = this.transferStudents.bind(this);
    this.getClassroomInfo = this.getClassroomInfo.bind(this);

    this.renderManageStudents = this.renderManageStudents.bind(this);
    this.renderAddStudents = this.renderAddStudents.bind(this);
  }

  componentDidMount() {
    this.csvSelector = document.querySelector('#csvSelector');
    this.csvSelector.addEventListener('change', (e) => {
      const file = this.csvSelector.files[0];
      this.importFile(file);
    });
    if (this.props.classroomId) {
      this.getClassroomInfo();
    }
  }

  componentDidUpdate(prevProps) {
    const { classroomId } = this.props;
    if (prevProps.classroomId !== classroomId) {
      this.getClassroomInfo();
    }
  }

  getClassroomInfo() {
    const { classroomId, classrooms, classroomDetails } = this.props;
    const classroomOptions = classrooms.reduce((memo, classroom) => {
      if (classroom.id === classroomId) return memo;
      memo.push({
        value: classroom.id,
        text: classroom.name,
      });
      return memo;
    }, []);


    let studentDetails = classroomDetails.students.map(student => ({
      id: student.id,
      name: student.get('fullName'),
      parentEmail: student.get('parentEmail'),
    }));

    studentDetails = __orderBy(studentDetails, ['name'],['asc']);

    this.setState({
      students: studentDetails,
      classroomOptions,
      selectedClassroom: classroomOptions[0],
      transferSuccess: false,
    });
  }

  toggleView(view) {
    this.setState(prevState => ({
      [view]: !prevState[view],
    }));
  }

  chooseFile(e) {
    e.preventDefault();
    this.csvSelector.click();
  }

  importFile(file) {
    var reader = new FileReader();

    reader.onload = (e) => {
      const text = reader.result;
      this.setState({ addStudentsText: text });
    }

    reader.readAsText(file);
  }

  validEmail(email) {
    const re = /.+@.+/;
    return re.test(email);
  }

  successAlert() {
    this.setState({ addSuccess: true });
    setTimeout(() => {
      this.setState({ addSuccess: false });
    }, 3000);
  }

  addStudents(e) {
    e.preventDefault();
    const { addStudentsText } = this.state;

    let students = addStudentsText !== '' ? addStudentsText.split('\n') : [];
    let emailCol = null;
    let hasFormError = false;
    const { classroomId } = this.props;

    if (!addStudentsText) return;

    this.setState({
      error: null,
      isButtonDisabled: true
    });

    students = students.reduce((memo, student, index) => {
      student = student.split(/[\t,]+/);

      for (var i = 0; i < student.length; i++) {
        if (this.validEmail(student[i])) {
          emailCol = i;
        }
      }

      let studentName = '';
      if (emailCol === null || emailCol > 1) {
        studentName = student[1] ? `${student[0].trim()} ${student[1].trim()}` : student[0].trim();
      } else if (emailCol === 1) {
        studentName = student[0].trim();
      }

      const parentEmail = student[emailCol] ? student[emailCol].trim() : '';

      if (studentName.length > 64 || parentEmail.length > 64) {
        this.setState({
          error: 'dashboard.students.create.error',
          isButtonDisabled: false,
        });
        hasFormError = true;
      }

      memo.push({
        fullName: studentName,
        parentEmail: parentEmail
      });

      return memo;
    }, []);

    if (hasFormError) return;

    addStudentsToClassroom({
      classroomId,
      studentDetails: students,
      sendEmail: this.state.sendEmail,
    }).then(success => {
      this.successAlert();
      this.props.refreshStudents();
      this.getClassroomInfo();
      this.setState({
        addStudentsText: '',
        isButtonDisabled: false,
      });

      Mixpanel.track('Dashboard (v1.3): Student Added');
    });
  }

  updateStudentName(e, index) {
    const { value } = e.target;

    this.setState(prevState => {
      prevState.students[index].name = value;
      prevState.students[index].didUpdate = true;

      return { students: prevState.students };
    })
  }

  updateParentEmail(e, index) {
    const { value } = e.target;
    this.setState(prevState => {
      prevState.students[index].parentEmail = value;
      prevState.students[index].didUpdate = true;

      return { students: prevState.students };
    });
  }

  updateStudent(e, student, index) {
    if (!student.didUpdate) return;

    updateStudent({
      id: student.id,
      name: student.name,
      parentEmail: student.parentEmail,
    }).then(() => (
      this.setState(prevState => {
        prevState.students[index].didUpdate = false;
        return { students: prevState.students };
      })
    ));
  }

  selectStudent(e, index) {
    this.setState(prevState => {
      prevState.students[index].isSelected = !prevState.students[index].isSelected;
      return { students: prevState.students };
    });
  }

  archiveStudent(e, student, index) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this student? This cannot be undone.")) {
      setStudentArchiveStatus({
        studentId: student.id,
        isArchived: true,
      }).then(() => {
        this.props.refreshStudents();
        this.setState(prevState => {
          prevState.students.splice(index, 1);
          return { students: prevState.students };
        });
      })
    }
  }

  transferStudents(e) {
    e.preventDefault();
    const selectedStudents = [];
    const { classroomOptions, students, selectedClassroom } = this.state;
    const { classroomId } = this.props;

    if (!classroomOptions.length) return;

    this.setState({ isButtonDisabled: true });

    students.forEach(student => {
      if (!student.isSelected) return;
      selectedStudents.push(student.id);

      transferStudent({
        studentId: student.id,
        fromClassroomId: classroomId,
        toClassroomId: selectedClassroom.value,
      });
    });

    this.setState(prevState => {
      selectedStudents.forEach(studentId => {
        const foundIndex = __findIndex(prevState.students, { id: studentId });
        prevState.students.splice(foundIndex, 1);
      });

      return {
        showClassroomTransfer: false,
        transferSuccess: true,
        students: prevState.students,
        isButtonDisabled: false
      };
    });
  }

  renderManageStudents() {
    const { vocabulary, siteContent } = this.props;
    const { selectedClassroom } = this.state;

    return (
      <div className="modal-content modal-content--manage">
        { this.state.showManageStudents && (
          <form className="students-form">
            <div className="table">
              { this.state.students.map((student, index) => (
                <div key={student.id} className="row">
                  <input
                    type="checkbox"
                    name={student.id}
                    onChange={(e) => this.selectStudent(e, index)}
                  />
                  <input
                    className="name row-input"
                    value={student.name}
                    maxLength="64"
                    onChange={(e) => this.updateStudentName(e, index)}
                    onBlur={(e) => this.updateStudent(e, student, index)}
                  />
                  {
                    !siteContent.isPartner &&
                    <input
                      className="parent-email row-input"
                      placeholder="Enter parent email"
                      value={student.parentEmail}
                      maxLength="64"
                      onChange={(e) => this.updateParentEmail(e, index) }
                      onBlur={(e) => this.updateStudent(e, student, index)}
                    />
                  }
                  <Button
                    className="button--xs"
                    onClick={(e) => this.archiveStudent(e, student, index)}
                    eventParams={{ name: 'Archive Student' }}
                  >
                    delete
                  </Button>
                </div>
              ))}
            </div>
            { (this.state.classroomOptions.length > 0 && this.state.students.length > 0) && (
              <div className="form-buttons">
                <Button
                  className="button--small button--wide"
                  disabled={this.state.isButtonDisabled}
                  onClick={(e) => {
                    this.setState({
                      showClassroomTransfer: true,
                      transferSuccess: false,
                    });
                    e.preventDefault();
                  }}
                >transfer to new classroom</Button>
              </div>
            )}
            { this.state.transferSuccess && (
              <div className="transfer-container">
                <p>{vocabulary["dashboard.student.textUpperPlural"]} were successfully transferred to {selectedClassroom.text} classroom</p>
              </div>
            )}
            { this.state.showClassroomTransfer && (
              <div className="transfer-container">
                <div className="transfer-select">
                  Transfer {vocabulary["dashboard.student.textPlural"]} to classroom:
                  <DropdownSelect
                    onSelect={(selectedClassroom) => this.setState({ selectedClassroom })}
                    options={this.state.classroomOptions}
                  />
                </div>
                <Button
                  className="button--small button--wide"
                  onClick={this.transferStudents}
                  eventParams={{ name: 'Transfer Students' }}
                >transfer</Button>
              </div>
            )}
          </form>
        )}
      </div>
    );
  }

  renderAddStudents() {
    const { vocabulary, siteContent } = this.props;
    return (
      <div className="modal-content modal-content--manage">
        { this.state.showAddStudents && (
          <form className="students-form">
            <div className="import-csv">
              Type or copy and paste from a spreadsheet.
              <Button
                className="button--xs button--white button--shadow"
                onClick={this.chooseFile}
                eventParams={{ name: 'Import Student CSV' }}
              >Import CSV</Button>
            </div>
            <input
              id="csvSelector"
              type="file"
              className="hide"
              accept=".csv"
            />
            <p>
              {vocabulary['dashboard.student.textUpper']}'s First Name and Last Initial
              {
                !siteContent.isPartner &&
                <span>, Parent@email.com (optional)</span>
              }
            </p>
            <textarea
              name="addStudents"
              placeholder={ `Ada L${siteContent.isPartner ? '' : ', mom@mom.com'}\nAlan T${siteContent.isPartner ? '' : ', dad@dad.com'}` }
              value={ this.state.addStudentsText }
              onChange={ e => this.setState({ addStudentsText: e.target.value }) }
            />
            {
              !siteContent.isPartner &&
              <div>
                <input
                  type="checkbox"
                  checked={this.state.sendEmail}
                  onChange={ e => this.setState({ sendEmail: e.target.checked }) }
                />
                <label>Send a welcome email to each parent's email address</label>
              </div>
            }
            <div className={`form-error ${this.state.error ? 'is-visible' : ''}`}>
              { vocabulary[this.state.error] }
            </div>
            { this.state.addSuccess &&
              <div className="successBox">{vocabulary["dashboard.student.textUpper"]}(s) successfully added!</div>
            }
            <div className="form-buttons">
              <Button
                className="button--small button--wide"
                onClick={this.addStudents}
                disabled={this.state.isButtonDisabled}
                eventParams={{ name: 'Add Students' }}
              >add</Button>
            </div>
          </form>
        )}
      </div>
    );
  }

  render() {
    const { vocabulary } = this.props;

    return (
      <div className="classrooms-detail-manage-students-container">
        <h3
          className="modal-title clickable"
          onClick={ () => this.toggleView('showManageStudents') }
        >
          <i className={`arrow ${!this.state.showManageStudents ? 'expanded' : ''}`} />
          manage {this.state.students.length} {vocabulary['dashboard.student.textPlural']}
        </h3>
        {this.renderManageStudents()}
        <h3
          className="modal-title clickable"
          onClick={ () => this.toggleView('showAddStudents') }
        >
          <i className={`arrow ${!this.state.showAddStudents ? 'expanded' : ''}`} />
          add {vocabulary['dashboard.student.textPlural']}
        </h3>
        {this.renderAddStudents()}
      </div>
    );
  }
}

export default ClassroomsDetailManageStudents;
