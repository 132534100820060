import React, { useState } from 'react';
import { Mixpanel } from '../../../../utils/mixpanel';
import './ConnectCode.scss';
import Button from '../../../../components/elements/DashboardButton';
import { createClassroomCode } from '../../../../services/classroom';
import { getCurrentUserID } from '../../../../services/teacher';

function ConnectCode(props) {
  const [code, setCode] = useState();
  const { vocabulary } = props;

  const generateCode = () => {
    createClassroomCode({ classroomId: props.classroomId }).then(
      (data) => {
        if (data.code) {
          setCode(data.code);
        } else {
          // error
          console.error(props.vocabulary['dashboard.classrooms.show.codeError']);
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  const openMoreInfoLink = () => {
    window.open(
      'https://drive.google.com/file/d/1o9Y56Ekk9jb8-CUafiGK_W1csoqOBfTC/view?usp=sharing',
      '_blank',
      'noopener,noreferrer'
    );
    Mixpanel.track('Dashboard (v1.4): Link Clicked', {
      parseAccountId: getCurrentUserID(),
      destinationUrl: 'https://drive.google.com/file/d/1o9Y56Ekk9jb8-CUafiGK_W1csoqOBfTC/view?usp=sharing',
    });
  };

  return (
    <div className="classroom-detail-connect-container">
      <h3 className="modal-title">
        {vocabulary['dashboard.classrooms.show.createCode']} - {props.classroomName}
      </h3>
      <div className="content">{vocabulary['dashboard.classrooms.show.codeInstructions']}</div>
      {code ? (
        <div className="codeDiv">
          Code: <span className="code">{code}</span>
        </div>
      ) : (
        ''
      )}
      <div className="content">{code ? vocabulary['dashboard.classrooms.show.codeResponse'] : ''}</div>
      <div className="noteContainer">
        <div className="note">{code ? vocabulary['dashboard.classrooms.show.codeNote'] : ''}</div>
        {code && (
          <a onClick={openMoreInfoLink} className="infoLink">
            More information
          </a>
        )}
      </div>
      <div className="form-buttons">
        <Button className="button--small button--wide" onClick={generateCode}>
          {vocabulary['dashboard.classrooms.show.generateCode']}
        </Button>
      </div>
    </div>
  );
}

export default ConnectCode;
