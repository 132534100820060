import React, { Component } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { getCompanyConfig } from '../../services/parse';
import Parse from 'parse';
import __isEmpty from 'lodash/isEmpty';
import __camelCase from 'lodash/camelCase';
import __toLower from 'lodash/toLower';

import { Mixpanel } from '../../utils/mixpanel';
import './App.scss';
import PrivateRoute from '../../components/global/PrivateRoute';
import Header from '../../components/global/Header';
import Footer from '../../components/global/Footer';
import Home from '../Home';
import Login from '../Login';
import Profile from '../Profile';
import Register from '../Register';
import Dashboard from '../Dashboard';
import Classrooms from '../Classrooms';
import ClassroomDetail from '../Classrooms/Detail';
import ClassroomsCreate from '../Classrooms/Create';
import CreateSuccess from '../Classrooms/CreateSuccess';
import Lessons from '../Dashboard/Lessons';
import Resources from '../Dashboard/Resources';
import ContactUs from '../Dashboard/Resources/ContactUs';
import ForParents from '../Dashboard/Resources/ForParents';
import Standards from '../Dashboard/Resources/Standards';
import SolutionGuides from '../Dashboard/Resources/SolutionGuides';
import ResourceVideos from '../Dashboard/Resources/ResourceVideos';
import TableWrapper from '../../components/global/TableWrapper';
import Play from '../Dashboard/Play';
import MoreInfo from '../MoreInfo';
import PasswordRecovery from '../PasswordRecovery';
import NotFoundPage from '../404';
import CommunityPage from '../Dashboard/Community';
import Clever from '../Clever';

const { detect, detectDevice } = require('../../utils/detect-browser');

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClient: false,
      currLocale: '',
      vocabulary: null,
      siteContent: null,
    };

    this.setLocale = this.setLocale.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
  }

  componentDidMount() {
    //set to English first
    this.getPageData();
    this.trackPageView();
    this.setState({ isClient: true });

    //wait for change in language
    window.addEventListener(
      'storage',
      (e) => {
        if (e.storageArea === localStorage) {
          if (this.state.locale !== localStorage.getItem('locale')) {
            this.setLocale();
          }
        }
      },
      false
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.trackPageView();
    }
  }

  trackPageView() {
    if (Parse.User.current()) {
      const browser = detect();
      const device = detectDevice();

      Mixpanel.track(`Dashboard (v1.3): Page View`, {
        parseAccountId: Parse.User.current() && Parse.User.current().id ? Parse.User.current().id : null,
        url: window.location.href,
        params: window.location.search,
        deviceType: device,
        deviceOS: browser.os,
      });
    } else {
      console.log('no user, not sending page view');
    }

    window.dataLayer.push({
      'event': 'virtualPageView',
      'page': {
        'title': 'Dashboard',
        'url': window.location.href,
      },
    });
  }

  setLocale(e) {
    if (!__isEmpty(e)) {
      this.setState({ currLocale: e.target.value }, () => {
        this.finishSwitchingLocale();
      });
      localStorage.setItem('locale', e.target.value);
    } else {
      this.finishSwitchingLocale();
    }
  }

  async getPageData() {
    try {
      let options = {};
      if (process.env.NODE_ENV === 'development') {
        options = { partner: '' }; // any other partner or ''
      }

      const siteContent = await getCompanyConfig(options);
      this.setState({ siteContent: siteContent }, () => this.setLocale());
    } catch (error) {
      console.error(error);
    }
  }

  finishSwitchingLocale() {
    if (this.state.siteContent === null) {
      return;
    }

    const locale =
      localStorage.getItem('locale') !== null
        ? localStorage.getItem('locale')
        : this.state.currLocale !== ''
        ? this.state.currLocale
        : 'en_US';

    const company = __toLower(__camelCase(this.state.siteContent.company));
    fetch(`/locale/${company}/locale-${locale}.json`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((fileContents) => {
        const englishVocabulary = this.state.englishVocabulary;
        this.setState({
          currLocale: locale,
          vocabulary: {
            ...englishVocabulary,
            ...fileContents,
          },
        });
      });
  }

  render() {
    if (!this.state.isClient || !this.state.vocabulary) {
      return null;
    }

    return (
      <div className="app-container">
        <Header
          selectedLocale={this.state.currLocale}
          onSelectLocale={this.setLocale}
          vocabulary={this.state.vocabulary}
          siteContent={this.state.siteContent}
        />
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              window.location.origin.includes('codeninjas') ? (
                <Redirect to="/login" />
              ) : (
                <Home {...this.state} />
              )
            }
          />
          <Route path="/login" render={() => <Login {...this.state} />} />
          <Route
            path="/password-recovery"
            render={() => <PasswordRecovery {...this.state} />}
          />
          <Route
            path="/register"
            render={() =>
              window.location.origin.includes('codeninjas') ? (
                <Redirect to="/login" />
              ) : (
                <Register {...this.state} />
              )
            }
          />
          <Route path="/clever">
            <Clever {...this.state} />
          </Route>

          <PrivateRoute path="/more-info">
            <MoreInfo {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <Profile {...this.state} />
          </PrivateRoute>

          <PrivateRoute exact path="/dashboard">
            <Dashboard {...this.state} />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/play">
            <Play {...this.state} />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/community">
            <CommunityPage {...this.state} />
          </PrivateRoute>

          <PrivateRoute exact path="/dashboard/classrooms">
            <Classrooms {...this.state} />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/classrooms/create">
            <ClassroomsCreate {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/classrooms/create/success">
            <CreateSuccess {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/classroom/:id">
            <ClassroomDetail {...this.state} />
          </PrivateRoute>

          <PrivateRoute exact path="/dashboard/lessons">
            <Lessons {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/lessons/plans/lessons">
            <TableWrapper
              {...this.state}
              filename={'lessons.plans.lessons'}
              name={'LessonsPlansLessons'}
              page={'lessons'}
              hasDownloads={true}
              footnote={
                '*These can be completed at any point in the sequence of lessons.'
              }
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/lessons/unplugged-activities">
            <TableWrapper
              {...this.state}
              filename={'lessons.unplugged'}
              name={'LessonsUnpluggedActivities'}
              page={'lessons'}
              hasDownloads={true}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/lessons/summer-jump">
            <TableWrapper
              {...this.state}
              filename={'lessons.summerjump'}
              name={'LessonsSummerJump'}
              page={'lessons'}
              hasDownloads={true}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/lessons/AGmini-lessons">
            <TableWrapper
              {...this.state}
              filename={'lessons.minilessons'}
              name={'miniLessons'}
              page={'lessons'}
              hasDownloads={true}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/lessons/elaIntegrated-lessons">
            <TableWrapper
              {...this.state}
              filename={'lessons.elaIntegratedLesson'}
              name={'elaIntegratedLessons'}
              page={'lessons'}
              hasDownloads={true}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/lessons/plans/glossary">
            <TableWrapper
              {...this.state}
              filename={'lessons.plans.glossary'}
              name={'LessonsPlansGlossary'}
              page={'lessons'}
              hasDownloads={true}
            />
          </PrivateRoute>

          <PrivateRoute exact path="/dashboard/resources">
            <Resources {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/contact-us">
            <ContactUs {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/for-parents">
            <ForParents {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/standards">
            <Standards {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/getting-started-guide">
            <TableWrapper
              {...this.state}
              name={'ResourcesGettingStartedGuide'}
              filename={'resources.getting-started'}
              page={'resources'}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/videos">
            <ResourceVideos {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/downloadable-images">
            <TableWrapper
              {...this.state}
              name={'ResourcesDownloadImages'}
              filename={'resources.downloadable-images'}
              page={'resources'}
              hasDownloads={true}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/faq">
            <TableWrapper
              {...this.state}
              name={'ResourcesFaq'}
              page={'resources'}
            />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard/resources/solution-guides">
            <SolutionGuides {...this.state} />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/solution-guides/overview">
            <TableWrapper
              {...this.state}
              name={'ResourcesSolutionGuidesOverview'}
              filename={'resources.solution-guides'}
              page={'resources'}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/solution-guides/chapter1">
            <TableWrapper
              {...this.state}
              name={'ResourcesSolutionGuidesChapter1'}
              filename={'resources.solution-guides'}
              page={'resources'}
              itemIndex={0}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/solution-guides/chapter2">
            <TableWrapper
              {...this.state}
              name={'ResourcesSolutionGuidesChapter2'}
              filename={'resources.solution-guides'}
              page={'resources'}
              itemIndex={1}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/solution-guides/chapter3">
            <TableWrapper
              {...this.state}
              name={'ResourcesSolutionGuidesChapter3'}
              filename={'resources.solution-guides'}
              page={'resources'}
              itemIndex={2}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/solution-guides/chapter4">
            <TableWrapper
              {...this.state}
              name={'ResourcesSolutionGuidesChapter4'}
              filename={'resources.solution-guides'}
              page={'resources'}
              itemIndex={3}
            />
          </PrivateRoute>
          <PrivateRoute path="/dashboard/resources/solution-guides/chapter5">
            <TableWrapper
              {...this.state}
              name={'ResourcesSolutionGuidesChapter5'}
              filename={'resources.solution-guides'}
              page={'resources'}
              itemIndex={4}
            />
          </PrivateRoute>

          <Route component={NotFoundPage} />
        </Switch>
        <Footer
          vocabulary={this.state.vocabulary}
          siteContent={this.state.siteContent}
          pathName={this.props.location.pathname}
        />
        <NotificationContainer ref="notification-container" />
      </div>
    );
  }
}

export default withRouter(App);
