const __isEmpty = require('lodash/isEmpty');
const Parse = require('parse');

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JS_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_URL;

export const login = (params) => {
  return new Promise((resolve, reject) => {
    Parse.User.logIn(params.username.toLowerCase(), params.password).then((user) => {
      return getTeacher();
    }).then((teacher) => {
      resolve(teacher);
    }, (error) => {
      reject(error);
    });
  });
};

export const register = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('RegisterTeacher', params).then((user) => {
      resolve(user);
    }, (error) => {
      reject(error);
    });
  });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    Parse.User.logOut().then(() => {
      sessionStorage.clear();
      resolve();
    }, (error) => {
      reject(error);
    });
  });
};

export const update = (params) => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SetTeacherProperties', params).then((user) => {
      resolve(user);
    }, (error) => {
      reject(error);
    });
  });
};

export const syncClassrooms = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('SyncClassrooms', {}).then((user) => {
      resolve(user);
    }, (error) => {
      reject(error);
    });
  });
};

export const getTeacher = () => {
  return new Promise((resolve, reject) => {
    Parse.Cloud.run('GetTeacher', {}).then((teacher) => {
      sessionStorage.setItem('teacherData', JSON.stringify(teacher));
      resolve(teacher);
    }, (error) => {
      reject(error);
    });
  });
};

export const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    Parse.User.requestPasswordReset(email).then(() => {
      resolve();
    }, (error) => {
      reject(error);
    });
  });
};

export const isLoggedIn = () => {
  return !__isEmpty(Parse.User.current());
};

export const getCurrentUser = () => {
  // user is already saved by Parse in local storage so we will use that one
  return localStorage.getItem('Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser');
};

export const getCurrentUserID = () => {
  const user = JSON.parse(localStorage.getItem('Parse/' + process.env.REACT_APP_PARSE_APP_ID + '/currentUser'));
  return user.objectId;
}